var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['single', { 'project': _vm.$route.meta.type }]},[_c('main',[(_vm.title && _vm.$route.meta.type == 'project')?_c('h1',{staticClass:"title typo--h1",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),(_vm.description)?_c('Description',{staticClass:"first-description",attrs:{"data":_vm.description}}):_vm._e(),_vm._l((_vm.blocks),function(block,index){return _c(block.component,{key:block.attrs.id,tag:"component",class:[
        block.blockName === 'acf/media' ? 'media-block' : false,
        index === 0 ? 'first-block' : false,
        block.blockName === 'acf/heading' ? 'heading-block' : false,

      ],attrs:{"data":block.blockName === 'core/columns' ? block.innerBlocks :
        block.attrs.data
          ? { ...block.attrs.data, ratio: true }
          : block.innerHTML}})})],2),_c('div',{ref:"overlayEnd",attrs:{"id":"overlay-end"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }